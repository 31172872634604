import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import styles from "./index.module.css";

export default function Home() {
  return (
    <Layout
      seoTitle="One stop solution for all financial needs"
      seoDescription="A registered CA firm catering to all your financial needs. Services related to auditing, taxation, consultancy and many more are offered"
    >
      <section className={styles.hero}>
        <div className={styles.container}>
          <h1 className={styles.heroText}>
            One stop solution for all financial needs
          </h1>
          <h3 className={styles.heroSubText}>
            Looking for financial consulting or accounting. Look no further, get
            in touch with us now.
          </h3>
          <p className={styles.heroContactUs}>
            <Link to="/contact/" className={styles.heroContactUsLink}>
              Get in touch
            </Link>
          </p>
        </div>
      </section>

      <section className={`${styles.about} ${styles.container}`}>
        <h2 className={styles.aboutTitle}>About the firm</h2>
        <p className={styles.aboutBody}>
          We are a Chartered Accountants firm providing Assurance, Taxation ,
          Advisory and other services. We laid the foundation stone of our firm
          on September 1, 2017 as a sole-proprietorship firm under the name &
          style of R L R & Associates with our office in Kolkata.
        </p>
        <Link to="/about/" className={styles.moreLink}>
          Know more about the firm.
        </Link>
        <p className={styles.aboutBody}>
          The firm is engaged in the activities in the line of GST Consultancy,
          Corporate Laws, Secretarial Compliances, Taxation Matters, Audit and
          Accountancy covering a wide range of sub-activities related to the
          profession. The major and significant activities taken care by the
          organization are audit, taxation, consultancy, accounting, secreterial
          works.
        </p>
        <Link to="/services/" className={styles.moreLink}>
          Know more about the services offered.
        </Link>
      </section>
    </Layout>
  );
}
